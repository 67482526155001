<template>
	<v-dialog
		v-model="show_bid_box"
		persistent
		height="auto"
		width="100%"
		transition="slide-fade"
		hide-overlay
		light
	>
		<v-row no-gutters class="map-header d-flex px-8">
			<v-col cols="6" class="d-flex align-center"></v-col>
			<v-col cols="6" class="d-flex align-center justify-end">
				<div
					v-on:click="show_bid_box = false"
					class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
				>
					Close
				</div>
			</v-col>
		</v-row>
		<v-row no-gutters class="div-content">
			<div v-html="data"></div>
		</v-row>
	</v-dialog>
</template>

<script>
import { getDisclaimer } from "../../services/registerservice";
export default {
	props: {
		value: Object,
	},

	data() {
		return {
			show_bid_box: false,
			data: "",
		};
	},

	methods: {
		async readDisclaimer() {
			this.show_bid_box = true;
			let data = await getDisclaimer();
			this.data = JSON.parse(JSON.stringify(data.message));
		},
	},
};
</script>

<style lang="scss" scoped>
.map-header {
	z-index: 9;
	content: "";
	height: 80px;
	background: #ffffff;
	border-bottom: 1px solid #dde2ee;

	.text-tag-btn {
		height: 32px;
		width: 127px;
		cursor: pointer;
		font-family: Inter-Medium;
		font-size: 15px;
		color: #216fff;
	}
}
.div-content {
	background: #f7f9fc;
	height: 100% !important;
	z-index: 9 !important;
	padding: 32px !important;
}
</style>
